import React from "react";
import imgPreview from './1.png'
import img1 from './2.gif'
import img2 from './3.png'
import imgVizData from './vizData.png'

const AwardsAndPress = () => {
  return <div style={{ marginTop: 16 }}>
   <h5>Press & Blogs</h5>
    <a href="https://www.visualisingdata.com/2020/10/best-of-the-visualisation-web-july-2020/" target="_blank" rel="noopener noreferrer"><img alt="" src={imgVizData} style={{ width: 250 }} /></a>
    <div><strong>Visualising Data</strong>: Best of the visualisation Web, July 2020.</div> 
  </div>
};

const Content = () => {
  return <div>
    <img alt="" src={img1} style={{ width: '100%', marginBottom: 16 }} />
    <img alt="" src={img2} style={{ width: '100%', marginBottom: 16 }} />
  </div>
};

const Project = () => {
  return {
    id: "nz-commuting",
    preview: imgPreview,
    title: "New-Zeland Commuting",
    tag: "Experiment",
    description: <div>This visual experiment is an attempt to come up with an original, fun, and interactive visual representation of the 2018 Census commuter data released on 17 June 2020. It was supposed to be my entry to the <a rel="noopener noreferrer" href="https://www.stats.govt.nz/2018-census/there-and-back-again-data-visualisation-competition" target="_blank">Stats NZ data visualisation competition</a> but I realized midway through that the competition is open to New Zealand residents only. Anyway, it was interesting to explore how to move millions of data points real time using Three.js</div>,
    awardsAndPress: <AwardsAndPress />,
    url: "http://nz-commuters.s3-website-eu-west-1.amazonaws.com/",
    date: new Date("2020-07-01"),
    content: <Content />
  }
};

export default Project;