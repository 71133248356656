import React from "react";
import imgPreview from './noisy-city.png'
import img1 from './1.png'
import imgLAvenir from './lavenir.jpg'
import loudNumbers from './loudNumbers.jpg'

const AwardsAndPress = () => {
  return <div style={{ marginTop: 16 }}>
    <h5>Press & Blogs</h5>
    <a href="https://m.lavenir.net/cnt/dmf20200925_01512692/sa-carte-sonore-de-bruxelles-fait-grand-bruit-le-pire-c-est-le-bois-de-la-cambre?goto=%2fcnt%2fdmf20200925_01512692%2fsa-carte-sonore-de-bruxelles-fait-grand-bruit-le-pire-c-est-le-bois-de-la-cambre" target="_blank" rel="noopener noreferrer"><img alt=""  src={imgLAvenir} style={{ width: 150, margin: 10 }} /></a>
    <a href="https://twitter.com/loudnumbers/status/1309489300296347648" target="_blank" rel="noopener noreferrer"><img alt=""  src={loudNumbers} style={{ width: 150, margin: 10 }} /></a>
  </div>
};

const Content = () => {
  return <div>
    <img alt=""  salt="" rc={img1} style={{ width: '100%', marginBottom: 16 }} />
  </div>
};

const Project = () => {
  return {
    id: "noisy-city",
    preview: imgPreview,
    title: "Noisy City",
    tag: "Experiment",
    description: 'Data visualization and sonification experiment about noise pollution in Brussels, which you can mouseover to hear what different levels of noise pollution actually sound like.',
    awardsAndPress: <AwardsAndPress />,
    url: "https://noisy-city.jetpack.ai",
    date: new Date("2020-09-01"),
    content: <Content />
  }
};

export default Project;