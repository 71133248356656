import React from "react";
import img1 from './brusselsStreetGenderInequality.gif'
import img2 from './BrusselsStreetGenderInequality.jpg'
import rtbf from './rtbf.jpg' 

const AwardsAndPress = () => {
  return <div style={{ marginTop: 16 }}>
    <h5>Press</h5>
    <a href={"https://www.rtbf.be/info/monde/detail_elections-americaines-la-carte-qui-change-le-regard-sur-les-resultats-des-republicains-et-des-democrates?id=10632495"} target="_blank" rel="noopener noreferrer"><img alt="" src={rtbf} style={{ width: 150 }} /></a>
  </div>
};

const Content = () => {
  return <div>
    <img alt="" src={img2} style={{ width: '100%', marginBottom: 16 }} />
  </div>
};

const Project = () => {
  return {
    id: "street-gender",
    preview: img1,
    title: "Street Gender",
    tag: "Experiment",
    description: <div>Only 6% of Brussels streets are named after women. <a href="http://equalstreetnames.brussels" target="blank">Open Knowledge BE</a> organized the production of these statistics with the help of 60 volunteers who tagged the gender of each street in Brussels. The picture you are seeing shows all the streets of Brussels named after people, they are colored in pink if the street was named after a woman and blue in case of a man.</div>,
    awardsAndPress: <AwardsAndPress />,
    date: new Date("2020-03-01"),
    url: "https://observablehq.com/@karimdouieb/brussels-streets-gender-inequality",
    content: <Content />
  }
};

export default Project;