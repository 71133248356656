import React from "react";
import imgPreview from './earthquakes.png'

/*const Content = () => {
  return <div style={{ width:"50vw", height: "50vw"}}>
    <iframe width="100%" height="100%" src="https://www.youtube.com/embed/6vDYAZrnqbM" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
  </div>
};*/

const Project = () => {
  return {
    id: "earthquakes",
    preview: imgPreview,
    title: "Earthquakes",
    tag: "Experiment",
    description: <div>Experimentation with Three.js showing earthquakes with exaggerated depth.</div>,
    url:"https://observablehq.com/d/a264648cf8bfef05",
    date: new Date("2020-05-01"),
    //content: <Content />
  }
};

export default Project;