import React from "react";
import imgPreview from './opioids.jpg'
import img1 from './1.png'
import img2 from './2.png'
import img3 from './3.png'
import img4 from './4.png'
import img5 from './5.png'
import imgBelfius from './belfius.png'

const AwardsAndPress = () => {
  return <div style={{ marginTop: 16 }}>
    <h5>Awards & Press</h5>
    <a href="https://www.belfius.be/about-us/fr/press-room/prix-de-la-presse/laureats-2019" target="_blank" rel="noopener noreferrer"><img alt=""  src={imgBelfius} style={{ width: 200 }} /></a>
    <div>Nominated for the 2019 Belfius Belgian press award.</div>
  </div>
};


const Content = () => {
  return <div>
    <img alt=""  src={img1} style={{ width: '100%', marginBottom: 16 }} />
    <img alt=""  src={img2} style={{ width: '100%', marginBottom: 16 }} />
    <img alt=""  src={img3} style={{ width: '100%', marginBottom: 16 }} />
    <img alt=""  src={img4} style={{ width: '100%', marginBottom: 16 }} />
    <img alt=""  src={img5} style={{ width: '100%', marginBottom: 16 }} />
  </div>
};

const Project = () => {
  return {
    id: "opioids",
    preview: imgPreview,
    title: "Opioids",
    fullTitle: "The pain of Belgians",
    tag: "Data Journalism",
    description: <div>The USA is experiencing a considerable health crisis. Over-prescription of painkillers causes millions of addictions and thousands of deaths. Belgium is preserved. Are we sure? More than a million of Belgians consumed a form of opioid in 2017. Contramal (tramadol), Oxycontin (oxycodone) or Durogesic (fentanyl), does that ring a bell? Here is the map of Belgium’s pain.</div>,
    awardsAndPress: <AwardsAndPress />,
    url: "http://medor-opioids.s3-website-eu-west-1.amazonaws.com/",
    date: new Date("2019-10-01"),
    content: <Content />
  }
};

export default Project;