import React from "react";
import BrusselsDiversity from "./brussels-diversity.jpeg";
import img from './sl-2019.png'
import img1 from './1.png'
import img2 from './2.png'
import img3 from './3.png'
import img4 from './4.png'
import img5 from './5.png'

const AwardsAndPress = () => {
  return <div style={{ marginTop: 16 }}>
    <h5>Awards</h5>
    <a href="https://www.informationisbeautifulawards.com/showcase/4325-brussels-a-lovely-melting-pot" target="_blank" rel="noopener noreferrer">
      <img alt=""   src={img} style={{ width: 150 }} />
    </a>
  </div>
};

const Content = () => {
  return <div>
    <img alt=""  src={img1} style={{ width: '100%', marginBottom: 16 }} />
    <img alt=""  src={img2} style={{ width: '100%', marginBottom: 16 }} />
    <img alt=""  src={img3} style={{ width: '100%', marginBottom: 16 }} />
    <img alt=""  src={img4} style={{ width: '100%', marginBottom: 16 }} />
    <img alt=""  src={img5} style={{ width: '100%', marginBottom: 16 }} />
  </div>
};

const Project = () => {
  return {
    id: "brussels-diversity",
    preview: BrusselsDiversity,
    title: "Brussels Diversity",
    fullTitle: "Brussels. A Lovely Melting-Pot.",
    tag: "Data Journalism",
    description: 'A data visualization essay exploring Brussels and its people. It shows how diverse and culturally rich Brussels can be. It provides plain facts that make you proud to live in this city while opening your eyes to some of its challenges.',
    awardsAndPress: <AwardsAndPress />,
    url: "https://brussels-diversity.jetpack.ai",
    date: new Date("2019-04-01"),
    content: <Content />
  }
};

export default Project;