import React from "react";
import imgPreview from './temperature-anomalies.png'

const Content = () => {
  return <div style={{ width:"50vw", height: "50vw"}}>
    <iframe title="video_temp_anomalies" width="100%" height="100%" src="https://www.youtube.com/embed/6vDYAZrnqbM" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
  </div>
};

const Project = () => {
  return {
    id: "temperature-anomalies",
    preview: imgPreview,
    title: "Temperature Anomalies",
    fullTitle: "Global Surface Temperature Anomalies",
    tag: "Coporate",
    description: <div>This is a visual experiment showing the global surface temperature anomalies situation over the course of ~130 years. The baseline is defined as the 1971 - 2000 average in degrees Celsius. Positive discrepancies are represented as bright bumps whereas negative discrepancies are represented as deep dark dents.</div>,
    url:"https://observablehq.com/@karimdouieb/global-surface-temperature-anomalies",
    date: new Date("2020-06-01"),
    content: <Content />
  }
};

export default Project;