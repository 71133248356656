import React from "react";
import imgPreview from './Maternite.original.jpg'
import img2 from './Gardes.original.jpg'
import img3 from './ConditionsDeTravail.original.jpg'
import img4 from './Supervision.original.jpg'

const Content = () => {
  return <div>
    <img alt=""  src={img2} style={{ width: '100%', marginBottom: 16 }} />
    <img alt=""  src={img3} style={{ width: '100%', marginBottom: 16 }} />
    <img alt=""  src={img4} style={{ width: '100%', marginBottom: 16 }} />
  </div>
};


const Project = () => {
  return {
    id: "medor-maccs",
    preview: imgPreview,
    title: "Assistant doctors fatigue",
    fullTitle: "Assistant doctors fatigue",
    tag: "Data journalism",
    description: <div>This project aims at visualising the responses of a survey conducted accross a good fraction of Belgian assistant doctors. The survey addressed 4 majors subjects: maternity, guards, working conditons and suppervision. This was a collaboration done with Médor a small investigation newspaper.</div>,
    url:"https://medor.coop/nos-series/enquete-sante/la-fatigue-des-maccs/?full=1#continuer-a-lire",
    date: new Date("2021-01-14"),
    content: <Content />
  }
};

export default Project;