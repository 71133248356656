import React from "react";
import imgPreview from './tornadoes.gif'
//import img1 from './1.png'
import imgVizData from './vizData.png'

const AwardsAndPress = () => {
  return <div style={{ marginTop: 16 }}>
    <h5>Press & Blogs</h5>
    <a href="https://www.visualisingdata.com/2020/10/best-of-the-visualisation-web-july-2020/" target="_blank" rel="noopener noreferrer"><img alt=""  src={imgVizData} style={{ width: 250 }} /></a>
    <div><strong>Visualising Data</strong>: Best of the visualisation Web, July 2020.</div>
  </div>
};

/*const Content = () => {
  return <div>
    <img alt=""  src={img1} style={{ width: '100%', marginBottom: 16 }} />
  </div>
};*/

const Project = () => {
  return {
    id: "tornadoes",
    preview: imgPreview,
    title: "Tornadoes",
    tag: "Experiment",
    description: 'A tornado chart™ used to show the incleasing amount of the tornados on U.S. ground over the years.',
    awardsAndPress: <AwardsAndPress />,
    url: "https://observablehq.com/@karimdouieb/tornados",
    date: new Date("2020-07-01"),
    //content: <Content />
  }
};

export default Project;