import React from "react";
import imgPreview from './covid19-impact.gif'
import img1 from './1.png'
import img2 from './2.png'


const Content = () => {
  return <div>
    <img alt=""  src={img1} style={{ width: '100%', marginBottom: 16 }} />
    <img alt=""  src={img2} style={{ width: '100%', marginBottom: 16 }} />
  </div>
};

const Project = () => {
  return {
    id: "covid19-impact",
    preview: imgPreview,
    title: "COVID-19 Impact on Road Transport",
    tag: "Corporate",
    description: 'Together with WABCO we have analysed the activity trends in the European road transport industry during the lockdown period. At its worst, European traffic was 28% below the situation post lockdown.',
    //awardsAndPress: <AwardsAndPress />,
    url: "https://www.wabco-customercentre.com/solutioncentre/s/transport-data-public?language=en_US&utm_campaign=fleet-transport-data&utm_medium=organic_social&utm_source=linkedin",
    date: new Date("2020-05-01"),
    content: <Content />
  }
};

export default Project;