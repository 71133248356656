import React from "react";
import ImgEuropeElectricity from './europe-electricity.png';

const Project = () => {
  return {
    id: "europe-electricity",
    preview: ImgEuropeElectricity,
    title: "European Electricity Production",
    tag: "Experiment",
    description: <div>This chart showing the Europe electricity production is an adaptation of the "How each state generated electricity in 2019" dataviz published in an <a href="https://www.washingtonpost.com/climate-environment/2020/07/30/biden-calls-100-percent-clean-electricity-by-2035-heres-how-far-we-have-go/?arc404=true&utm_medium=social&utm_source=twitter&utm_campaign=wp_graphics" target="_blank" rel="noopener noreferrer">The Washington Post</a> article and created by <a href="https://twitter.com/JohnMuyskens" target="_blank" rel="noopener noreferrer">John Muyskens</a></div>,
    url:"https://observablehq.com/@karimdouieb/europe-electricity-production",
    date: new Date("2020-08-01"),
  }
};

export default Project;