import React from "react";
//import { Row, Col } from "react-flexbox-grid";
// SCSS
import "./hero.scss";
//Assets
//import HeroImage from '../../assets/hero/hero-image.png';
//Components
//import Button from '../ui-components/button/button';

const hero = () => (
  <div id="hero" style={{ height: '1vh' }}>
  {/*<div className="hero" id="hero">
    <div className="wrapper">
      <Row>
        <Col md={12} lg={8}>
          <div className="hero-info">
            <h1 className="weight800 font60">Hi</h1>
            <h1 className="weight800 font60">
              My name is Karim Douieb. 
              I like to make data driven visuals.
            </h1>
            <p className="font12">
              This is my portfolio. Its main purpose is mostly to avoid going through my twitter timeline in order to retrieve references to my own projets. 
            </p>
            <Button label="SEND MESSAGE" target={"contact"} />
          </div>
        </Col>
        <Col md={12} lg={4}>
        </Col>
      </Row>
    </div>
  </div>*/}
  </div>
);

export default hero;
