import React from "react";
import imgPreview from './cause-of-deaths.jpeg'
import img1 from './1.png'

const Content = () => {
  return <div>
    <img alt=""  src={img1} style={{ width: '100%', marginBottom: 16 }} />
  </div>
};

const Project = () => {
  return {
    id: "cause-of-deaths",
    preview: imgPreview,
    title: "Cause of Deaths",
    fullTitle: "Primary Cause of Deaths",
    tag: "Experiment",
    description: <div>Primary cause of deaths in the USA by gender and age from 1970 to 2017. My first go at hand drawn dataviz.</div>,
    url:"https://observablehq.com/collection/@karimdouieb/cause-of-deaths-in-the-world",
    date: new Date("2020-10-01"),
    content: <Content />
  }
};

export default Project;