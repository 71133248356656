import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { Row, Col } from "react-flexbox-grid";
import moment from "moment"
import "./Project.scss";

import { projects } from "../Project/ProjectList"

import "../ui-components/button/button.scss";

const Project = () => {
  const { projectId } = useParams();
  
  const project = projects.find(p => p.id === projectId)
  
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  return <div id="project">
    <div className="wrapper">
      <div id="hero" style={{ height: '1vh' }}></div>
      {project && 
        <div>
          <Row>
            <Col md={12} lg={4}>
              <h1>{project.fullTitle || project.title}</h1>
              {project.date && <h5>{moment(project.date).format('MMMM YYYY')}</h5>}
              <div>{project.description}</div>
              {project.url && <div className="button" onClick={()=> window.open(project.url, "_blank")} style={{ display: 'inline-block', marginTop: 16, marginBottom: 16 }}>
              <div className="button-link">
                VISIT PROJECT
              </div>
              </div>}
              {project.awardsAndPress}
            </Col>
            <Col md={12} lg={8}>
              <img src={project.preview} alt="project" />
            </Col>
          </Row>
          <div style={{ marginTop: 32 }}>
            {project.content}
          </div>
        </div>
      }
      {!project && <h5>Unknown Project</h5>}
    </div>
  </div>
};

export default Project;
