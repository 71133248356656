import React from "react";
import img1 from './1.jpeg'
import img2 from './2.png'

const Content = () => {
  return <div>
    <img alt=""  src={img2} style={{ width: '100%', marginBottom: 16 }} />
  </div>
};

const Project = () => {
  return {
    id: "face-of-power",
    preview: img1,
    title: "The Face of Power",
    tag: "Experiment",
    description: <div>As a small experiment, I created a composite image from Platon's photographic portraits of power published in the <a href="https://www.newyorker.com/magazine/2009/12/07/portraits-of-power-platon" target="_blank" rel="noopener noreferrer">New Yorker</a>. The averaging portrait was then enhanced using a AI photo algorithm.</div>,
    date: new Date("2020-02-01"),
    content: <Content />
  }
};

export default Project;