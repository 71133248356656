import React from "react";
import imgPreview from './TryToImpeachThis.gif'
import img1 from './1.png'
import img2 from './2.png'
import img3 from './3.png'
import img4 from './4.png'
import img5 from './5.png'
import imgVizData from './vizData.png'
import imgFlowingData from './flowingData.png'

const AwardsAndPress = () => {
  return <div style={{ marginTop: 16 }}>
    <h5>Awards & Press</h5>
    <a href="https://flowingdata.com/2019/12/19/best-data-visualization-projects-of-2019/" target="_blank" rel="noopener noreferrer"><img alt=""  src={imgFlowingData} style={{ width: 300 }} /></a>
    <div style={{ marginBottom: 16 }}>Best Data Visualization Projects of 2019.</div>
    <a href="https://www.visualisingdata.com/2019/12/best-of-the-visualisation-web-october-2019/" target="_blank" rel="noopener noreferrer"><img alt=""  src={imgVizData} style={{ width: 200 }} /></a>
    <div><strong>Visualising Data</strong>: Best of the visualisation Web, October 2019.</div>
  </div>
};


const Content = () => {
  return <div>
    <img alt=""  src={img1} style={{ width: '100%', marginBottom: 16 }} />
    <img alt=""  src={img2} style={{ width: '100%', marginBottom: 16 }} />
    <img alt=""  src={img3} style={{ width: '100%', marginBottom: 16 }} />
    <img alt=""  src={img4} style={{ width: '100%', marginBottom: 16 }} />
    <img alt=""  src={img5} style={{ width: '100%', marginBottom: 16 }} />
  </div>
};

const Project = () => {
  return {
    id: "try-to-impeach-trhis",
    preview: imgPreview,
    title: "Try to impeach this",
    tag: "Data Journalism",
    description: <div>This work was a <a rel="noopener noreferrer" href="https://twitter.com/karim_douieb/status/1181695687005745153" target="_blank">response</a> to a tweet of Laura Trump during the impeachment process. Its aims is to bring awarness about the fact that traditional electoral maps are in many cases a misleading way to represent the outcome of an election.</div>,
    awardsAndPress: <AwardsAndPress />,
    url: "http://try-to-impeach-this.jetpack.ai/",
    date: new Date("2019-10-01"),
    content: <Content />
  }
};

export default Project;