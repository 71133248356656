//import React from "react";

import BrusselsDiversity from "./brusselsDiversity/Project";
import Lineas from "./lineas/Project";
import FaceOfPower from "./faceOfPower/Project"
import StreetGender from "./streetGender/Project";
import TV from "./tv/Project";
import EuropeElectricity from "./europeElectricity/Project";
import CauseOfDeaths from "./causeOfDeaths/Project";
import NoisyCity from "./noisyCity/Project";
import Tornadoes from "./tornadoes/Project";
import NzCommuting from "./nzCommuting/Project";
import TemperatureAnomalies from "./temperatureAnomalies/Project";
import Earthquakes from "./earthquakes/Project";
import TryToImpeachThis from "./tryToImpeachThis/Project";
import WabcoCovid from "./covid19-impact/Project";
import Opioids from "./opioids/Project";
import Twitter from "./twitter/Project";
import Maccs from "./maccs/Project";

export const projects = [
  Maccs(),
  Twitter(),
  TryToImpeachThis(),
  Opioids(),
  BrusselsDiversity(),
  TV(),
  EuropeElectricity(),
  StreetGender(),
  CauseOfDeaths(),
  NoisyCity(),
  FaceOfPower(),
  NzCommuting(),
  TemperatureAnomalies(),
  Tornadoes(),
  Earthquakes(),
  WabcoCovid(),
  Lineas(),
]