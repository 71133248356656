import React from "react";
import imgPreview from './twitter.jpeg'
import img1 from './1.png'
import img2 from './2.png'
import img3 from './3.png'

const Content = () => {
  return <div>
    <img alt=""  src={img1} style={{ width: '100%', marginBottom: 16 }} />
    <img alt=""  src={img2} style={{ width: '100%', marginBottom: 16 }} />
    <img alt=""  src={img3} style={{ width: '100%', marginBottom: 16 }} />
  </div>
};

const Project = () => {
  return {
    id: "twitter",
    preview: imgPreview,
    title: "Political Twitter",
    tag: "Data Journalism",
    description: <div>We looked at how political Belgian leaders used their Twitter account: who tweets the most? At what time ? On what subject ? Who is the most popular? What are the different communication strategies used? To answer these questions, we extracted all the messages posted by the presidents of Belgian parties on the social network between January 2018 and September 2020. More than 20,000 tweets, thousands of likes and retweets ...</div>,
    url: "http://twitter-be-politics.s3-website-eu-west-1.amazonaws.com/",
    date: new Date("2020-11-01"),
    content: <Content />
  }
};

export default Project;