import React from "react";
import imgTV from './TV.jpeg'
import img1 from './1.png'
import img2 from './2.png'

const Content = () => {
  return <div>
    <img alt=""  src={img1} style={{ width: '100%', marginBottom: 16 }} />
    <img alt=""  src={img2} style={{ width: '100%', marginBottom: 16 }} />
  </div>
};

const Project = () => {
  return {
    id: "toerisme-vlaanderen",
    preview: imgTV,
    title: "Resident Attitudes towards Tourism",
    tag: "Coporate",
    description: <div>In the spring of 2020, tourism residents' studies were carried out in the Flemish art cities. <a href="https://www.toerismevlaanderen.be/kunststeden#bewonersonderzoek" target="_blank" rel="noopener noreferrer">This research</a> examines the attitude of the residents of Antwerp, Ghent, Leuven, Mechelen and Bruges towards tourism and visitors in their city. They were asked about the impact of tourism on their life in their city. In total, nearly 6,000 adults took part in the new study. We were responsible to convey the results of this study in a graphically engaging format.</div>,
    url:"https://toerisme-vlaanderen.jetpack.ai/en",
    date: new Date("2020-06-01"),
    content: <Content />
  }
};

export default Project;